import './exercise.css'
import { useEffect, useState } from 'react'
import { Container, Grid, Text, Paper, Image, Tooltip, Button } from '@mantine/core'
import { IconBarbell } from '@tabler/icons'
import { Checkboxes } from '../checkboxes'
import { addDoc, collection } from "firebase/firestore"
import { onSnapshot, DocumentData } from "firebase/firestore";
import db from '../../firebase'

export function Exercise() {


    const addQuote = async (payload: any) => {
        const collectionRef = collection(db, "Exercise");
        //const payload = { text: text, shortTitle: shortTitle, image:imageURL }
        await addDoc(collectionRef, payload)
    }

    const defaultExerciseData = [{
        bild: "",
        headline: "",
        text: "",
        muskelgruppen: [""],
        besonderheiten: [""],
        schwierigkeit: ""
    }]

    function AddStuff() {
        defaultExerciseData.map(item =>
            addQuote({
                bild: item.bild,
                headline: item.headline,
                text: item.text,
                muskelgruppen: item.muskelgruppen,
                besonderheiten: item.besonderheiten,
                schwierigkeit: item.schwierigkeit
            })
        )
    }

    const [initialExerciseData, setInitialExerciseData] = useState<DocumentData[]>(defaultExerciseData)
    const [exerciseData, setExerciseData] = useState<DocumentData[]>(defaultExerciseData)

    useEffect(() =>
        onSnapshot(collection(db, "Exercise"), (snapshot) => {
            setExerciseData(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })))
            setInitialExerciseData(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })))
        }),
        []
    );

    const checklist = ["Anfänger", "Fortgeschritten", "Profi"]
    const [filterSchwierigkeit, setFilterSchwierigkeit] = useState(checklist)

    //Fügt alle Muskelgruppen zu einer Liste zusammen
    useEffect((()=>{
        const muskelgruppenSet = new Set()
        initialExerciseData.map((item => item.muskelgruppen.forEach(muskelgruppenSet.add, muskelgruppenSet)))
        const muskelgruppen = Array.from(muskelgruppenSet)
        setMuskelgruppenFilter(muskelgruppen)
    }),[initialExerciseData])
    
    const muskelgruppenSet = new Set()
    initialExerciseData.map((item => item.muskelgruppen.forEach(muskelgruppenSet.add, muskelgruppenSet)))
    const muskelgruppen = Array.from(muskelgruppenSet)
    const [muskelgruppenFilter, setMuskelgruppenFilter] = useState(muskelgruppen)

    //Ändert die angezeigten Übungen, je nachdem welcher Filter ausgewählt ist
    useEffect((() => {
        let filteredExercises = initialExerciseData.filter(item => filterSchwierigkeit.includes(item.schwierigkeit))
        filteredExercises = (filteredExercises.filter(item => item.muskelgruppen.filter((item: string) => muskelgruppenFilter.includes(item)).length > 0))
        setExerciseData(filteredExercises)
    }), [filterSchwierigkeit, muskelgruppenFilter])

    function Farbe(Schwierigkeit: any) {
        switch (Schwierigkeit) {
            case "Anfänger":
                return "green"
            case "Fortgeschritten":
                return "yellow"
            case "Profi":
                return "red"
            default:
                return "white"
        }
    }

    return (

        <Container
        //size="xl"
        //style={{ marginTop: "30vh" }} my="xs"
        >
            <Grid grow>
                <Grid.Col md={1}>
                    <Grid.Col className='filterColumn'>
                        <Paper id="exercisePaper" shadow="xl" p="md">
                            <h1>Filter</h1>
                            <Checkboxes checked={filterSchwierigkeit} setChecked={setFilterSchwierigkeit} title="Schwierigkeit" checklist={checklist} />
                            <Checkboxes checked={muskelgruppenFilter} setChecked={setMuskelgruppenFilter} title="Muskelgruppen" checklist={muskelgruppen} />
                        </Paper>
                    </Grid.Col>
                </Grid.Col>
                <Grid.Col md={7} >
                    {/*<Grid.Col md={10} > */}
                    {exerciseData.map((item, index) =>
                        <Grid.Col key={index}>
                            <Paper id="exercisePaper" shadow="xl" p="md">
                                <Grid>
                                    <Grid.Col xs={4}>
                                        <Image
                                            radius="md"
                                            src={item.bild}
                                            alt="Random unsplash image"
                                        />              </Grid.Col>
                                    <Grid.Col xs={8}>
                                        <h1>{item.headline}</h1>
                                        <Text>
                                            {item.text}
                                        </Text>
                                        <h4>Muskelgruppen</h4>
                                        <ul>
                                            {item.muskelgruppen.map((item: any, index: any) => <li key={index}>{item}</li>)}
                                        </ul>
                                        <h4>Besonderheiten</h4>
                                        <ul>
                                            {item.besonderheiten.map((item: any, index: any) => <li key={index}>{item}</li>)}
                                        </ul>
                                        <Tooltip
                                            label={item.schwierigkeit}
                                            position="bottom"
                                            withArrow>
                                            <div id="Schwierigkeit" style={{ backgroundColor: Farbe(item.schwierigkeit) }}>
                                                <IconBarbell id="BarbellLogo" size={42} stroke={1.5} color={item.schwierigkeit === "Fortgeschritten" ? "black" : "white"} />
                                            </div>
                                        </Tooltip>
                                    </Grid.Col>
                                </Grid>

                            </Paper>
                        </Grid.Col>
                    )}
                </Grid.Col>
            </Grid>
        </Container>

    );
}