import { Container, Grid, Image, Paper, Table, TypographyStylesProvider } from '@mantine/core'
import { getAuth } from "firebase/auth";
import { TextEditor } from '../textEditor'
import { onSnapshot, collection, DocumentData } from "firebase/firestore";
import { useEffect, useState } from 'react';
import db from '../../firebase'

import './events.css'
import { ImageCarousel } from '../carousel';

interface Article {
    title?: string;
    shortTitle: string;
    description?: string;
    text: string;
}

export function Events() {

    const [articles, setArticles] = useState<DocumentData[]>([{headline:"",bilder:["https://firebasestorage.googleapis.com/v0/b/cali38.appspot.com/o/Anlagen%2FPrinzenpark1.jpg?alt=media&token=31e47c86-68db-4184-a024-c7ccbd4f0ef9"],text:""}]);
    useEffect(() =>
        onSnapshot(collection(db, "Articles"), (snapshot) => {
            setArticles(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })))
        }),
        []
    );

    const events = [{
        headline:"Artikel über Brückenchallenge?",
        bilder:[
            "https://firebasestorage.googleapis.com/v0/b/cali38.appspot.com/o/Anlagen%2FPrinzenpark1.jpg?alt=media&token=31e47c86-68db-4184-a024-c7ccbd4f0ef9",
            "https://firebasestorage.googleapis.com/v0/b/cali38.appspot.com/o/Anlagen%2FPrinzenpark2.jpg?alt=media&token=19d16008-af5d-42ae-bdac-043062082cb2",
            "https://firebasestorage.googleapis.com/v0/b/cali38.appspot.com/o/Anlagen%2FPrinzenpark3.jpg?alt=media&token=a5b58154-f2c7-48cb-8f04-41b5faa48051",
            "https://firebasestorage.googleapis.com/v0/b/cali38.appspot.com/o/Anlagen%2FPrinzenpark4.jpg?alt=media&token=86c7f54c-0b44-4b81-951d-a80d8625b799",
            "https://firebasestorage.googleapis.com/v0/b/cali38.appspot.com/o/Anlagen%2FPrinzenpark5.jpg?alt=media&token=04c7e7e8-7adb-448b-9b60-5dc21ae5e33a",
            "https://firebasestorage.googleapis.com/v0/b/cali38.appspot.com/o/Anlagen%2FPrinzenpark6.jpg?alt=media&token=163ee17a-a8a5-466f-a0f2-bfb0bdd2583f"
                    ],
        text: '<p><em>Im Fullscreen ist der Kopf abgeschnitten, aber aufm Handy geht\'s</em></p><p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>'
    }]

    return (
        <Container
            size="sm" 
            id='article-container' my="xs">

            <Grid grow>

                <Grid.Col md={7} >
                    {articles.map((item, index) =>
                        <Grid.Col key={index} >
                            <Paper id={item.headline} shadow="xl" p="md" style={{ position: "relative" }}>
                                <Grid>
                                    <Grid.Col xs={12}>
                                        <ImageCarousel images={item.bilder} />
                                    </Grid.Col>
                                    <Grid.Col xs={12}>
                                        <h1  style={{fontFamily:"Arial, Helvetica, sans-serif"}}>{item.headline}</h1>
                                        <TypographyStylesProvider>
                                            <div dangerouslySetInnerHTML={{ __html: item.text }} />
                                        </TypographyStylesProvider>

                                    </Grid.Col>
                                </Grid>

                            </Paper>
                        </Grid.Col>
                    )}
                </Grid.Col>

            </Grid>
        </Container>
    )
}