import { Image, Modal } from '@mantine/core'
import { useState } from "react";
import './imageImpression.css'

export function ImageImpression() {

    const imageURLs = [
        "https://firebasestorage.googleapis.com/v0/b/cali38.appspot.com/o/files%2Ffranzi_planche.jpg?alt=media&token=0e2fc0c3-c111-4899-b02f-86043269eb6d",
        "https://firebasestorage.googleapis.com/v0/b/cali38.appspot.com/o/files%2FVierfachLever.jpg?alt=media&token=30f885b6-339f-41e7-a96c-65433b5417fa",
        "https://firebasestorage.googleapis.com/v0/b/cali38.appspot.com/o/files%2Fgruppenfoto_gym_tour.jpg?alt=media&token=ad442bd9-8efb-41dd-b726-b628dadddc03",
        "https://firebasestorage.googleapis.com/v0/b/cali38.appspot.com/o/files%2Fbauchworkout.jpg?alt=media&token=315da95e-244d-4955-b5a5-29fe139945d8",
        "https://firebasestorage.googleapis.com/v0/b/cali38.appspot.com/o/files%2FFloHut.jpg?alt=media&token=b54a0632-69db-4f0d-9b51-46269ad398d0",
        "https://firebasestorage.googleapis.com/v0/b/cali38.appspot.com/o/files%2Fcali_tag.jpg?alt=media&token=31c41adf-2aac-45f9-930d-70ed9d448968",
        "https://firebasestorage.googleapis.com/v0/b/cali38.appspot.com/o/files%2Fkkkbacklever.jpeg?alt=media&token=d9c16e65-aea2-491c-8844-95f8eb3e0cb9",
        "https://firebasestorage.googleapis.com/v0/b/cali38.appspot.com/o/files%2FAnfangsgruppe.jpg?alt=media&token=4a2c7ed6-34df-4dd1-b3e6-64e516334837",
        "https://firebasestorage.googleapis.com/v0/b/cali38.appspot.com/o/files%2FKonrad_renew.jpg?alt=media&token=8daa8cf6-849c-41a9-9038-3b759d2159da",
        "https://firebasestorage.googleapis.com/v0/b/cali38.appspot.com/o/files%2Firina_backlever.jpg?alt=media&token=ab1d002c-dd28-4e19-8073-dad2da4c4cb4",
        "https://firebasestorage.googleapis.com/v0/b/cali38.appspot.com/o/files%2Fcali_equipment.jpg?alt=media&token=bfcf6178-18ef-4f1e-86a8-214406ebe961",
        "https://firebasestorage.googleapis.com/v0/b/cali38.appspot.com/o/files%2FNicolaiWettkampf.png?alt=media&token=c0dbf02d-2100-4cc4-a9b7-bfecba246d71",
        "https://firebasestorage.googleapis.com/v0/b/cali38.appspot.com/o/files%2Fgruppenfoto_voll.jpg?alt=media&token=0910d509-5832-42d5-8f70-a1b7fede62c5"
    ]

    function OpenModal(url:string){
        setImageUrl(url);
        setModalOpen(true)
    }

    const [modalOpen, setModalOpen] = useState(false)
    const [imageUrl, setImageUrl] = useState("")
    return (
        <div>
            <Modal
                centered
                overlayColor={"black"}
                overlayOpacity={0.55}
                size="auto"
                overlayBlur={3}
                opened={modalOpen}
                withCloseButton={false}
                onClose={() => setModalOpen(false)}
            >
                <Image
                    radius="md"
                    src={imageUrl}
                    alt="Random unsplash image"
                    fit='contain'
                    height={"70vh"}
                />

            </Modal>
            <div className="animated-grid">
                {imageURLs.map((item,index) =>
                    <div
                        key={index}
                        className="card card-tall card-wide"
                        style={{ backgroundImage: "url("+item+")"}}
                        onClick={() => OpenModal(item)}
                    />)}
                    
            </div>

        </div>
    )
}